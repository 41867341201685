.learn {
    margin: 50px auto 20px;
    width: 100%;

    @media(max-width: $laptop) {
        margin: 68px 0 20px;
    }

    &-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
    }

    &__bold {
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: 0.05em;
        color: $deep-gray;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 110%;
        letter-spacing: 0.05em;
        color: $deep-gray;
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__plug {
        margin-top: 50px;
    }

    &__progressbar {
        margin-top: 15px;
    }

    &__top {
        max-width: 1000px;
        margin: 0 auto;
    }

    &__map {
        max-width: 1000px;
        margin: 55px auto 0;
        margin-bottom: -4px;

        @media(max-width: $tablet) {
            margin-top: 0px;
        }
    }

    &__cards {
        margin-top: 55px;

        @media(max-width: $tablet) {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            z-index: 1;
        }

        .slick {

            &-slider {
                position: relative;
                display: flex;
                align-items: center;
                gap: 0 10px;
                padding: 0 25px;
                width: 100%;
            }

            &-list {
                max-width: 1000px;
                margin: 0 auto;
            }

            &-track {
                display: flex;
                flex-direction: row;
                gap: 12px;

            }

            &-arrow {
                position: absolute;
                background-repeat: no-repeat;
                background-size: 16px 28px;
                color: transparent !important;
                top: 50%;
                transform: translateY(-50%);
                transition: .5s;
                width: 30px;
                height: 30px;
                overflow: hidden;

                &.slick-disabled {
                    opacity: 0;
                    pointer-events: none;
                }

                &::before {
                    opacity: 0 !important;
                    pointer-events: none;
                }
            }

            &-prev {
                left: 0;
                background-image: url('../../../img/arrows/slider-arrow-left.svg') !important;
                background-repeat: no-repeat !important;
                background-position: left !important;
            }

            &-next {
                right: 0;
                background-image: url('../../../img/arrows/slider-arrow-right.svg') !important;
                background-repeat: no-repeat !important;
                background-position: right !important;
            }

            &-slide {
                div>div {
                    display: flex !important;
                }
            }
        }
    }

    &__card {
        margin-top: 4px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__desc {
        @media(max-width: $tablet) {
            display: none !important;
        }
    }

    &__mobile {
        display: none;

        @media(max-width: $tablet) {
            display: flex;
            flex-direction: column;
            max-width: 350px;
            margin: 0 auto;
        }
    }
}