.puls {
    // animation: signs-puls ease 2s infinite;

    &::before,
    &::after {
        position: absolute;
        transform: scale(0.9);
    }

    &:before {
        content: 'Почати';
        bottom: calc(100% + 2px);
        left: 50%;
        transform: translateX(-50%) scale(0.9);
        background-color: $white;
        border-radius: 14px;
        padding: 5px 15px;
        font-weight: 400;
        font-size: 10px;
        line-height: 110%;
        text-transform: uppercase;
        color: $deep-gray;
        z-index: 5;
        animation: signs-puls ease 2s infinite;
    }

    &::after {
        content: '';
        background-image: url('/assets/img/arrows-left.svg');
        background-repeat: no-repeat;
        background-size: 23px 20px;
        width: 23px;
        height: 20px;
        left: -30px;
        top: 25px;
        animation: signs-move ease 2s infinite;
    }

    &--star {
        &::before {
            bottom: calc(100% + 5px);
        }

        &::after {
            top: 30%;
        }
    }

    &--hint {
        &::before {
            content: 'Підказка';
            bottom: calc(100% + 5px);
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.46);
            font-size: 14px;
            line-height: 100%;
            padding: 5px 10px;
        }

        &::after {
            background-image: url('/assets/img/arrows-left-gray.svg');
            top: 8px;
        }
    }
}

@keyframes signs-puls {
    0% {
        transform: translateX(-50%) scale(0.9);
    }

    50% {
        transform: translateX(-50%) scale(1.1);
    }

    100% {
        transform: translateX(-50%) scale(0.9);
    }
}

@keyframes signs-move {
    0% {
        transform: translateX(2px);
    }

    50% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(2px);
    }
}