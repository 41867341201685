// colors
$white: #ffffff;
$white-opacity: rgba(255, 255, 255, 0.5);

$black: #000000;

$yellow: #efb700;
$yellow-hover: #dba800;

$red: #b81d13;

$blue: #2291e3;

$dark: #333333;

$deep-gray: #39393f;
$light-gray: #d9d9d9;
$medium-gray: #ebebeb;
$gray: #868282;
$gray-1: #ebebeb;
$gray-2: #46494f;
$gray-3: #c4c4c4;
$gray-4: #b8bfc6;

$green: #10aa57;

// breakpoints
$desktop: 1200px;
$laptop: 992px;
$tablet: 768px;
$phone: 568px;
