@import "../../../assets/style/main/base/variables.scss";

.label {
  border-radius: 20px;
  padding: 1px 15px;
  height: 16px;
  font-size: 10px;
  line-height: 1.4;
  color: $deep-gray;
  width: fit-content;

  &--yellow {
    background-color: $yellow;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}
