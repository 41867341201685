*,
::before,
::after {
  box-sizing: border-box;
}

html {
  &::-webkit-scrollbar {
    width: 8px;
    height: 100%;
    background-color: #c4c4c4;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #868282;
  }
}

html,
body {
  height: 100%;

  @media (min-width: 992px) {
    overflow: initial;
  }
}

body {
  &.open {
    overflow: hidden;
  }
}

p,
button,
input,
body,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

select,
textarea,
input,
button {
  font: inherit;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
}

ul,
li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

input[type="search"] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="file"] {
  display: none;
}

#root {
  height: 100%;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin-inline: auto;
  padding-inline: 15px;

  &--sm {
    max-width: 1000px;
  }
}

.main {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: $deep-gray;
  background-color: $light-gray;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__logo {
    display: none;

    @media (max-width: $tablet) {
      display: flex;
      justify-content: center;
      padding: 25px 38px 0;
    }

    img {
      max-width: 350px;
      width: 100%;
      height: 84px;
      object-fit: contain;
    }
  }

  &__footer {
    margin: auto 0 0 0;

    &--desktop {
      @media (max-width: $tablet) {
        display: none;
      }
    }
  }

  &__container {
    height: 100%;
  }
}

.star {
  display: flex;
  flex: 0 0 50px;

  &__line,
  &__base {
    transition: 0.5s;
  }
}

.place,
.ecxlamation {
  display: flex;
  flex: 0 0 50px;

  &__line {
    transition: 0.5s;
  }
}
