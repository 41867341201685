.footer-learn {
    padding: 40px 0;
    max-width: 100%;
    background-color: $deep-gray;
    position: static;
    box-shadow: none;
    margin: auto 0 0 0;

    @media(max-width: $tablet) {
        padding: 12px 0;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        @media(max-width: $laptop) {
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 25px;
        }

        @media(max-width: $tablet) {
            padding: 0 15px;
        }
    }

    &__logo {
        width: 170px;
        height: 40px;
        flex: 0 0 170px;
        cursor: pointer;

        @media(max-width: $tablet) {
            width: 145px;
            height: 35px;
            flex: 0 0 145px;
        }
    }

    &__list {
        display: flex;
        align-items: center;
        gap: 20px 50px;

        @media(max-width: $laptop) {
            justify-content: space-between;
            width: 100%;
            gap: 20px 40px;
        }

        @media(max-width: $tablet) {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 15px;
        }
    }

    &__link {
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        color: $white;
        padding: 0;
        margin: 0;
        border: 0;

        @media(max-width: $tablet) {
            font-size: 16px;
        }

        &:hover {
            color: $white;
            text-decoration-line: underline;
            text-underline-offset: 4px;
        }
    }

    &__pay {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;

        @media(max-width: $laptop) {
            justify-content: flex-start;
        }

        img {
            width: 36px;
            height: 24px;
            object-fit: contain;
        }
    }
}