@import "../../../assets/style/main/base/variables.scss";

.progress {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr auto;
  gap: 30px 16px;

  @media (max-width: $laptop) {
    grid-template-columns: 1fr;
    gap: 50px 16px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
