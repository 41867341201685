@import "../../../assets/style/main/base/variables.scss";

.repetition-topics {
  display: grid;
  gap: 24px;
  padding: 32px 32px 60px;
  border-radius: 20px;
  background-color: $medium-gray;

  @media (max-width: $tablet) {
    padding: 20px 20px 30px;
  }
}

.repetition-topic {
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 1fr 140px;
  gap: 20px;
  padding-bottom: 24px;
  border-bottom: 1px solid $gray;

  @media (max-width: $tablet) {
    align-items: flex-start;
    grid-template-columns: 1fr auto;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.1;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.1;
    letter-spacing: 0.8px;
  }

  &__degree {
    display: flex;
    gap: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;

    span {
      @media (max-width: $tablet) {
        display: none;
      }
    }

    &--red {
      color: $red;
    }

    &--green {
      color: $green;
    }
  }

  &__icon {
    width: 15px;
    height: 16px;
    flex: 0 0 15px;
  }

  &__btn {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 0.9px;
    padding: 10px;
    height: fit-content;
    text-transform: capitalize;
    color: $deep-gray;

    @media (max-width: $tablet) {
      grid-column: span 2;
      grid-row: 2 / 3;
    }
  }
}
