.about {
    padding: 90px 0;

    &__container {
        max-width: 700px;   
    }

    &__title {
        font-weight: 600;
        font-size: 36px;
        line-height: 120%;
        text-align: center;
    }

    &__content {
        margin-top: 30px;

        p {
            margin-top: 20px;
            font-size: 18px;
            line-height: 120%;

            &:first-child {
                margin-top: 0;
            }
        }

        a {
            text-decoration-line: underline;
            text-underline-offset: 4px;
            color: #2291e3;
        }
    }
}