.card-tariff {
  position: relative;
  max-width: 570px;
  width: 100%;
  padding: 178px 44px 44px 44px;
  background-color: $gray-3;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $laptop) {
    padding: 180px 38px 38px 38px;
  }

  &__img {
    position: absolute;
    width: 180px;
    height: 180px;
    top: -28px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $gray-2;
    border-radius: 50%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 200px;
      height: 194px;
    }
  }

  &-title {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    color: $dark;

    &__text {
      position: relative;
      font-weight: 600;
      font-size: 24px;
      line-height: 17px;
      text-transform: uppercase;
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 14px;
      background-color: $yellow;
      z-index: 1;
    }
  }

  &__price {
    margin-top: 24px;
    font-size: 24px;
    line-height: 25px;

    @media (max-width: $laptop) {
      margin-top: 20px;
    }
  }

  &__text {
    margin: 24px 0;
    max-width: 365px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $gray-2;

    @media (max-width: $laptop) {
      margin: 16px 0;
    }
  }

  &__btn {
    margin: auto 0 0 0;
    max-width: 260px;

    @media (max-width: $tablet) {
      max-width: 100%;
    }
  }
}
