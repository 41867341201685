.progressbar {
    max-width: 100%;

    &-line {
        width: 100%;
        height: 13px;
        border-radius: 13px;
        background-color: #868282;

        @media(max-width: 768px) {
            background-color: #D9D9D9;
        }

        &__progress {
            width: 100%;
            height: 100%;
            border-radius: 13px;
        }
    }

    &--low {
        .progressbar {
            &-line {
                &__progress {
                    background-color: #B81D13;
                }
            }
        }
    }

    &--hight {
        .progressbar {
            &-line {
                &__progress {
                    background-color: #10AA57;
                }
            }
        }
    }

    &--done {
        .progressbar {
            &-line {
                &__progress {
                    background-color: #EFB700;
                }
            }
        }
    }

    &--locked {
        .progressbar {
            &-line {
                background-color: #D9D9D9;
            }
        }
    }
}