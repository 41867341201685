.start {
    position: relative;
    overflow: hidden;
    margin-bottom: 60px;
    margin-top: 40px;

    @media(max-width: $laptop) {
        margin: 85px 0;
    }

    @media(max-width: $tablet) {
        margin: 35px 0;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    &__clouds {
        position: absolute;
        width: 100%;
        height: 265px;
        top: 97px;
        left: 0;
        right: 0;
        z-index: 1;

        @media(max-width: $tablet) {
            height: 141px;
            top: 147px;
        }
    }

    &__cities {
        position: absolute;
        top: 250px;
        transform: translateX(0);
        display: flex;
        z-index: 2;
        transition: ease 7s;

        @media(max-width: $tablet) {
            top: 205px;
        }
    }

    &__city {
        width: 100%;
        height: 265px;

        @media(max-width: $laptop) {
            object-fit: cover;
        }
    }

    &__car {
        position: absolute;
        width: 563px;
        height: 210px;
        top: 375px;
        z-index: 3;

        @media(max-width: $tablet) {
            width: 345px;
            height: 130px;
            top: 400px;
            object-fit: contain;
        }
    }

    &__birds-two {
        position: absolute;
        top: 140px;
        left: 45%;
        width: 92px;
        height: 30px;
        z-index: 4;
        transition: ease 10s;

        @media(max-width: $tablet) {
            width: 50px;
            top: 175px;
            left: 85px;
        }
    }

    &__birds-three {
        position: absolute;
        top: 270px;
        left: 75%;
        width: 130px;
        height: 60px;
        z-index: 4;
        transition: ease 10s;

        @media(max-width: $tablet) {
            width: 90px;
            top: 240px;
            left: 70%;
        }
    }

    &__text {
        width: 510px;
        font-weight: 400;
        font-size: 24px;
        line-height: 108.4%;
        letter-spacing: 0.05em;
        color: $deep-gray;
        text-align: center;
        font-style: normal;
        padding-top: 555px;
        opacity: 0;
        transition: 5s ease;

        @media(max-width: $tablet) {
            font-size: 18px;
            width: 100%;
            padding-top: 500px;
        }

        @media(max-width: $phone) {
            padding-top: 430px;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 15px;

        @media(max-width: $tablet) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__btn {
        width: 250px;
        height: 60px;
        font-weight: 600;
        font-size: 24px;
        line-height: 22px;
        letter-spacing: -0.408px;
        text-transform: uppercase;
    }

    &.animation {
        .start {
            &__cities {
                transform: translateX(-100%);

                @media(max-width: $tablet) {
                    transform: translateX(-200%);
                }
            }

            &__birds-two {
                top: 110px;
                left: 55%;

                @media(max-width: $tablet) {
                    top: 175px;
                    left: 120px;
                }
            }

            &__birds-three {
                top: 200px;
                left: 85%;

                @media(max-width: $tablet) {
                    top: 210px;
                    left: 75%;
                }
            }

            &__text {
                opacity: 1;
            }
        }
    }
}