@import "../../../assets/style/main/base/variables.scss";

.plug {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-weight: 600;
  font-size: 22px;
  line-height: 120%;
  color: $deep-gray;
  text-align: center;
  margin: 0 0 100px 0;

  &__img {
    width: 300px;
    height: 200px;
    object-fit: contain;
  }

  &__btn {
    margin: 20px auto 0;
    max-width: 300px;
    width: 250px;
    height: 60px;
    letter-spacing: -0.408px;
    text-transform: uppercase;
  }
}
