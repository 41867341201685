.card {

    &--gold {
        &:hover {
            .star {
                &__base {
                    fill: #FADF75;
                }

                &__line {
                    fill: #FFF4C3;
                }
            }
        }

        &.applied {
            .card-learn {
                &__circle {
                    background-color: #FCECA7;
                }
            }
        }
    }

    &--green {
        .place {
            &__line {
                fill-opacity: 0;
            }
        }

        &:hover {
            .place {
                &__line {
                    fill-opacity: 0.4;
                }
            }
        }

        &.applied {
            .card-learn {
                &__circle {
                    background-color: #C2F3D9;
                }
            }
        }
    }

    &--red {
        .ecxlamation {
            &__line {
                fill-opacity: 0;
            }
        }

        &:hover {
            .ecxlamation {
                &__line {
                    fill-opacity: 0.4;
                }
            }
        }

        &.applied {
            .card-learn {
                &__circle {
                    background-color: #FFB0B0;
                }
            }
        }
    }

    &--locked {
        cursor: default !important;

        @media(max-width: $tablet) {
            background-color: $gray !important;
        }

        &:hover {
            box-shadow: none !important;
        }

        .card-learn {

            &__title,
            &__text {
                color: $gray;
            }
        }
    }

    &--gray {
        cursor: default !important;

        &:hover {
            box-shadow: none !important;
        }

        .place {
            path {
                fill: #D9D9D9;
                fill-opacity: 1;
            }
        }
    }

}

.card-learn {
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 150px;
    background: linear-gradient(180deg, #FFFFFF 0%, #EBEBEB 100%);
    border-radius: 20px;
    padding: 25px 25px 20px;
    margin: 45px 0;
    color: $deep-gray;
    position: relative;
    cursor: pointer;
    transition: .5s;

    &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }

    &__circle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -44px;
        background: #FFFFFF;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title,
    &__text {
        line-height: 110%;
        letter-spacing: 0.05em;
        text-align: center;
    }

    &__title {
        font-weight: 700;
        font-size: 24px;
        text-transform: uppercase;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        text-transform: lowercase;

        &::first-letter {
            text-transform: capitalize;
        }
    }

    &__progressbar {
        margin: auto 0 0 0;
        padding-top: 5px;

        .progressbar {
            &-line {
                height: 7px;
            }
        }
    }
}

.card-mobile {
    background-color: $white;
    padding: 10px 45px 10px 10px;
    clip-path: polygon(0% 0%, 87% 0, 100% 50%, 87% 99%, 0% 100%);
    display: flex;
    flex-direction: column;
    gap: 22px;
    cursor: pointer;

    &__base {
        display: flex;
        gap: 10px;
    }

    &__icon {
        width: 50px;
        height: 48px;
    }

    &__title {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $deep-gray;
    }

    &__theme {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.05em;
        color: $deep-gray;
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}