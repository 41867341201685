@import "../../../assets/style/main/base/variables.scss";

.btn {
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: #fff;
  text-transform: uppercase;
  height: 60px;
  padding: 20px 30px;
  border-radius: 20px;
  transition: all 0.4s ease;

  &--sm {
    padding: 15px;
    height: 50px;
  }

  &--md {
    font-size: 18px;

    @media (max-width: $laptop) {
      font-size: 16px;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--rounded-md {
    border-radius: 14px;
  }

  &--blue {
    background-color: #2291e3;

    &:hover {
      background-color: #1875ba;
      color: #fff;
    }
  }

  &--black {
    background-color: $deep-gray;
    color: $white;

    &:hover {
      background-color: $dark;
    }
  }

  &--red {
    background-color: #cc0000;
    color: #fff;

    &:hover {
      background-color: #b30000;
      color: #fff;
    }
  }

  &--green {
    background-color: #007500;

    &:hover {
      background-color: #015801;
    }

    &:disabled {
      background-color: #738573;
      color: rgb(210, 210, 210);
      cursor: default;
    }
  }

  &--mobile {
    display: none;

    @media (max-width: 991px) {
      display: block;
    }
  }

  &--yellow {
    color: $black;
    background-color: $yellow;

    &:hover,
    &:focus {
      background-color: $yellow-hover;
      color: $black;
    }
  }

  &--transparent {
    font-weight: 600;
    font-size: 24px;
    line-height: 108.4%;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    text-underline-offset: 4px;
    padding: 0;
    margin: 0;
    width: fit-content;
    background-color: transparent;
    color: $deep-gray;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      text-decoration-line: none;
    }
  }
}
