@import "../../../assets/style/main/base/variables.scss";

.repetition-statistics {
  display: flex;
  justify-content: space-between;
  gap: 50px;

  &__block {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    font-weight: 400;
    line-height: 1.2;
    cursor: pointer;

    @media (max-width: $tablet) {
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: $tablet) {
      gap: 8px;
    }

    &.active {
      .repetition-statistics {
        &__title {
          font-weight: 600;
          font-size: 24px;

          @media (max-width: $laptop) {
            font-size: 20px;
          }
        }
      }
    }
  }

  &__img {
    width: 84px;
    height: 84px;
    flex: 0 0 84px;

    @media (max-width: $laptop) {
      height: 64px;
      width: 64px;
      flex: 0 0 64px;
    }

    @media (max-width: $tablet) {
      height: 58px;
      width: 58px;
      flex: 0 0 58px;
    }
  }

  &__count {
    font-size: 36px;
    margin-bottom: 8px;

    @media (max-width: $laptop) {
      font-size: 32px;
      margin-bottom: 0;
    }

    @media (max-width: $tablet) {
      text-align: center;
      font-size: 28px;
    }

    &--green {
      color: $green;
    }

    &--red {
      color: $red;
    }
  }

  &__title {
    font-size: 18px;
    transition: 0.4s;

    @media (max-width: $tablet) {
      text-align: center;
    }
  }
}
