.sign {
    position: absolute;
    width: 64px;
    height: 90px;
    cursor: pointer;
    user-select: none;

    &--first {
        top: 140px;
        left: 301px;

        @media(max-width: $tablet) {
            top: 8px;
            left: 63%;
        }
    }

    &--second {
        top: 30px;
        left: 384px;

        @media(max-width: $tablet) {
            top: 124px;
            left: 80%;

        }
    }

    &--third {
        top: 70px;
        left: 467px;

        @media(max-width: $tablet) {
            top: 221px;
            left: 70%;
        }
    }

    &--four {
        top: 40px;
        left: 550px;

        @media(max-width: $tablet) {
            top: 265px;
            left: 40%;
        }
    }

    &--fifth {
        top: 65px;
        left: 636px;

        @media(max-width: $tablet) {
            top: 422px;
            left: 29%;
        }
    }

    &--sixth {
        top: 195px;
        left: 716px;

        @media(max-width: $tablet) {
            top: 530px;
            left: 42%;
        }
    }

    &--seventh {
        top: 220px;
        left: 799px;

        @media(max-width: $tablet) {
            top: 643px;
            left: 65%;
        }
    }

    &--eight {
        top: 190px;
        left: 882px;

        @media(max-width: $tablet) {
            top: 760px;
            left: 44%;
        }
    }

    &--nine {
        top: 140px;
        left: 980px;

        @media(max-width: $tablet) {
            top: 855px;
            left: 38%;
        }
    }

    &--ten {
        top: 120px;
        left: 1103px;

        @media(max-width: $tablet) {
            top: 953px;
            left: 63%;
        }
    }

    &--eleven {
        top: 55px;
        left: 1182px;

        @media(max-width: $tablet) {
            top: 1073px;
            left: 53%;
        }
    }

    &--twelve {
        top: 115px;
        left: 1272px;

        @media(max-width: $tablet) {
            top: 1127px;
            left: 29%;
        }
    }

    &--thirteen {
        top: 220px;
        left: 1340px;

        @media(max-width: $tablet) {
            top: 1253px;
            left: 38%;
        }
    }

    &--fourteen {
        top: 245px;
        left: 1432px;

        @media(max-width: $tablet) {
            top: 1370px;
            left: 46%;
        }
    }

    &--fifteen {
        top: 190px;
        left: 1510px;

        @media(max-width: $tablet) {
            top: 1453px;
            left: 77%;
        }
    }

    &--sixteen {
        top: 220px;
        left: 1605px;

        @media(max-width: $tablet) {
            top: 1566px;
            left: 75%;
        }
    }

    &--seventeen {
        top: 125px;
        left: 1700px;

        @media(max-width: $tablet) {
            top: 1669px;
            left: 56%;
        }
    }

    &--eighteen {
        top: 65px;
        left: 1785px;

        @media(max-width: $tablet) {
            left: 65%;
            top: 1820px;
        }
    }

    &--nineteen {
        left: 1875px;
        top: 115px;

        @media(max-width: $tablet) {
            left: 80%;
            top: 1950px;
        }
    }

    &--twenty {
        left: 1965px;
        top: 170px;

        @media(max-width: $tablet) {
            left: 50%;
            top: 2060px
        }
    }

    &--triangular {
        width: 85px;
        height: 90px;

        .sign__num {
            top: 22px;
        }
    }

    &--triangular_rotate {
        width: 85px;
        height: 90px;

        .sign__num {
            top: 10px;
        }
    }

    &--hexagon {

        .sign__num {
            top: 11px;
        }
    }

    &--rhombus {
        width: 60px;
        height: 90px;

        .sign__num {
            top: 12px;
        }
    }

    &--octagon {
        width: 64px;
        height: 90px;

        .sign__num {
            top: 11px;
        }
    }

    &--square {
        width: 60px;
        height: 90px;

        .sign__num {
            top: 12px;
            left: calc(50% + 5px);
        }
    }

    &--rectangle {
        width: 60px;
        height: 90px;

        .sign__num {
            top: 14px;
        }
    }

    &--locked {
        cursor: default;
    }

    &__num {
        position: absolute;
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $deep-gray;
        top: 14px;
        left: 50%;
        transform: translateX(-50%);

        &--white {
            color: $white;
        }
    }

    @keyframes signs-puls {
        0% {
            transform: scale(0.9);
        }

        50% {
            transform: scale(1);
        }

        100% {
            transform: scale(0.9);
        }
    }
}