.map {
    width: 100%;
    height: 350px;
    position: relative;

    &--short {
        .map__content {
            overflow-x: hidden;

            @media(max-width: $laptop) {
                overflow-x: scroll;
            }
        }
    }

    &__content {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: #EBEBEB;
        border-radius: 20px;
        overflow-x: scroll; 
        overflow-y: hidden;
        display: flex;

        &::-webkit-scrollbar {
            position: absolute;
            left: 20px;
            right: 20px;
           width: calc(100% - 40px);
           height: 8px;
           border-radius: 0 0 5px 5px;
           background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            height: 8px;
            border-radius: 5px;
            background-color: $gray;
        }
    }

    &__road {
        position: absolute;
        bottom: 0;
        left: -30px;
        right: 0;
        width: 2115px;
        height: 297px;
        margin-right: -30px;
        user-select: none;
    }

    &__progressbar {
        position: absolute;
        top: 30px;
        left: 170px;
    }

    &__star {
        position: absolute;
        width: 120px;
        height: 115px;
        top: 36px;
        left: 1950px;
        cursor: pointer;
        user-select: none;

        img {
            width: 100%;
            height: 100%;
        }

        &--right {
            top: 56px;
            left: initial;
            right: 95px;
            z-index: 5;

            @media(max-width: $laptop) {
                right: 25px;
            }

            @media(max-width: 920px) {
                top: 200px;
                left: 60%;
                right: initial;
            }
        }

        &--cursor {
            cursor: default;
        }
    }

    &__next {
        position: absolute;
        left: 0;
        top: 0;
        width: 110px;
        height: 38px;
        z-index: 5;
        user-select: none;
    }
}

.map-mobile {
    position: relative;
    z-index: 1;

    &__map {
        width: 100%;
        height: 100%;
        user-select: none;
    }

    &__star {
        position: absolute;
        bottom: 12px;
        right: 0;
        width: 98px;
        height: 94px;
        cursor: pointer;
        z-index: 2;
        user-select: none;

        &--cursor {
            cursor: default !important;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        transform: rotateX(0);
        user-select: none;

        &--rotate {
            transform: rotateX(180deg);
        }
    }

    &__progressbar {
        position: absolute !important;
        top: 100px;
        left: 0;
    }
}