.preference {
    padding: 60px 0 68px;
    background-color: $gray-1;

    @media(max-width: $tablet) {
        padding: 80px 0 0 0;
        background-color: $light-gray;
    }

    &__title {
        @media(max-width: $tablet) {
            margin: 0 auto;
            text-align: center;
        }
    }

    &__slider {
        margin-top: 36px;

        @media(max-width: $tablet) {
            margin-top: 32px;
        }

        .slick {
            &-track {
                display: flex;
            }

            &-list {
                @media(max-width: $laptop) {
                    margin: 0 auto;
                    width: calc(100% - 116px);
                }
            }

            &-arrow {
                position: absolute;
                background-repeat: no-repeat;
                background-size: 16px 28px;
                color: transparent !important;
                top: 50%;
                transform: translateY(-50%);
                transition: .5s;
                width: 40px;
                height: 40px;
                overflow: hidden;
                display: flex !important;

                &.slick-disabled {
                    opacity: 0.6;
                }

                &::before {
                    content: none;
                }
            }

            &-prev {
                left: 0;
                background-image: url('../../../img/arrows/slider-arrow-left.svg') !important;
                background-repeat: no-repeat !important;
                background-position: center !important;
            }

            &-next {
                right: 0;
                background-image: url('../../../img/arrows/slider-arrow-right.svg') !important;
                background-repeat: no-repeat !important;
                background-position: center !important;
            }

            &-slide {
                flex: 1 1 370px;
                margin-left: 32px;

                @media(max-width: $tablet) {
                    margin-left: 2px;
                }
                
                &:first-child {
                    margin-left: 0;
                }

                img {
                    display: flex;
                }
            }
        }
    }

    &-block {
        display: flex;
        flex-direction: column;

        &__img {
            width: 100px;
            height: 100px;
            flex: 0 0 100px;
            object-fit: contain;

            @media(max-width: $tablet) {
                width: 80px;
                flex: 0 0 80px;
            }
        }

        &__title {
            margin-top: 25px;
            font-weight: 600;
            font-size: 24px;
            line-height: 27px;
        }

        &__text {
            margin-top: 16px;
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;

            @media(max-width: $tablet) {
                font-size: 16px;
            }
        }
    }
}