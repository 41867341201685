.burger-learn {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    transition: .3s;

    &__line {
        position: absolute;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: $deep-gray;
        transition: .3s;

        &--first {
            top: 0;
        }

        &--second {
            top: 50%;
            transform: translateY(-50%);
        }

        &--third {
            bottom: 0;
        }
    }
}