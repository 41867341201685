@import "../../../assets/style/main/base/variables.scss";

.profile {
  margin: 65px auto 135px;
  max-width: 970px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: $laptop) {
    margin: 130px 0 135px;
  }
  svg path {
    fill: currentColor;
    stroke: currentColor;
  }

  &-user {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: $tablet) {
      gap: 10px;
    }

    &__img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 128px;
      height: 128px;
      flex: 0 0 128px;
      border-radius: 50%;
      background-color: $white;
      overflow: hidden;

      @media (max-width: $tablet) {
        width: 115px;
        height: 115px;
        flex: 0 0 115px;
      }

      img {
        width: 80px;
        height: 80px;
        object-fit: contain;

        @media (max-width: $tablet) {
          width: 70px;
          height: 70px;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: $tablet) {
        gap: 12px;
      }
    }

    &__name {
      margin-bottom: 4px;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.1;

      @media (max-width: $tablet) {
        font-size: 22px;
        margin-bottom: 0;
      }
    }

    &__subtitle {
      font-size: 18px;
      line-height: 1.4;

      @media (max-width: $tablet) {
        font-size: 16px;
      }
    }

    &__link {
      @media (max-width: $tablet) {
        font-size: 16px;
      }
    }
  }
}
