.nav {
  display: flex;
  align-items: center;
  //   justify-content: space-between;
  justify-content: space-evenly;
  gap: 10px;

  @media (max-width: $laptop) {
    display: none;
  }

  &__link {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: $deep-gray;
    display: flex;
    flex-wrap: nowrap;

    &:hover {
      text-decoration-line: underline;
      text-underline-offset: 4px;
    }
  }
}

.nav-mobile {
  position: fixed;
  height: calc(100% - 48px);
  width: 100%;
  background-color: $white;
  top: 50px;
  right: -200%;
  z-index: 10;
  opacity: 0;
  transition: 0.3s;

  &__top {
    padding: 38px 40px 28px;
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width: 427px) {
      padding: 38px 15px 28px;
    }
  }

  &__avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 2px solid $deep-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 64px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__name {
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: $deep-gray;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__position {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.05em;
    color: $deep-gray;
  }

  &__status {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.05em;
    padding: 5px 10px;
    background-color: $yellow;
    border-radius: 14px;
  }

  &__progressbar {
    margin: 0 0 0 auto;

    .progressbar-car {
      width: 64px;
      height: 64px;

      &__circle {
        width: 64px;
        height: 64px;
      }

      &__block {
        width: 52px;
        height: 52px;
      }

      &__img {
        width: 21px;
        height: 20px;
      }

      &__num {
        font-size: 14.2222px;
        line-height: 140%;
      }
    }
  }

  &__list {
    padding: 44px 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-top: 1px solid $gray;

    @media (max-width: 427px) {
      padding: 44px 15px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__icon {
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    background-color: $light-gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: $deep-gray;
  }

  &__bottom {
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 28px;

    @media (max-width: 427px) {
      padding: 0 16px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: $deep-gray;
  }

  &__btn {
    max-width: 136px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: $deep-gray;
  }

  &-icon {
    width: 22px !important;
    height: 22px !important;
  }
}
