@import "../../../assets/style/main/base/variables.scss";

.field {
  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.4;
    color: $deep-gray;

    @media (max-width: $tablet) {
      font-size: 16px;
    }

    & + .password {
      margin-top: 4px;
    }
  }

  &__select {
    margin-top: 4px;
  }
}
