.header-learn {
  max-width: 100%;
  padding: 18px 0;
  background-color: $gray-1;

  @media (max-width: $laptop) {
    border: 0;
    padding: 10px 0;
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;

    @media (max-width: $laptop) {
      padding: 0 15px;
    }
  }

  &__logo {
    width: 267px;
    height: 64px;
    flex: 0 0 267px;
    object-fit: contain;
    cursor: pointer;

    @media (max-width: $laptop) {
      width: 124px;
      height: 30px;
      flex: 0 0 124px;
    }
  }

  &__nav {
    max-width: 580px;
    flex-grow: 1;

    @media (max-width: $laptop) {
      display: none;
    }
  }

  &__profile {
    width: 42px;
    height: 42px;
    flex: 0 0 42px;

    @media (max-width: $laptop) {
      margin: 0 0 0 auto;
    }
  }

  &__btn {
    width: 135px !important;
    height: 37px;

    &--desktop {
      @media (max-width: $laptop) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @media (max-width: $laptop) {
        display: flex;
      }
    }
  }

  &__burger {
    display: none;
    width: 30px;
    height: 20px;

    @media (max-width: $laptop) {
      display: flex;
      justify-content: flex-end;
      transition: 0.3s;
    }
  }

  &__progressbar {
    width: 64px;
    height: 64px;

    @media (max-width: $laptop) {
      display: none;
    }

    .progressbar-car {
      width: 64px;
      height: 64px;

      &__circle {
        width: 64px;
        height: 64px;
      }

      &__block {
        width: 52px;
        height: 52px;
      }

      &__img {
        width: 21px;
        height: 20px;
      }

      &__num {
        font-size: 14.2222px;
        line-height: 140%;
      }
    }
  }

  &.open {
    .burger-learn {
      z-index: 10;
      width: 21px;
      height: 21px;
      flex: 0 0 21px;
      background-color: $black;
      border-radius: 50%;

      &__line {
        width: 60%;
        background-color: $white;

        &--first {
          left: 25%;
          top: 55%;
          transform: rotate(45deg) translateY(-50%) translateX(-16%);
        }

        &--second {
          opacity: 0;
          height: 0;
          transition: 0.3s;
        }

        &--third {
          left: 33%;
          bottom: 52%;
          transform: rotate(-45deg) translateY(-50%) translateX(-23%);
        }
      }
    }

    .header-learn {
      &__profile {
        z-index: 10;
      }

      &-overlay {
        opacity: 1;
        pointer-events: initial;
      }
    }

    .nav-mobile {
      right: 0;
      opacity: 1;
    }
  }

  .dropdown {
    @media (max-width: $laptop) {
      margin: 0 0 0 auto;
    }
  }
}
