.progressbar-car {
  position: relative;
  width: 135px;
  height: 135px;

  &__circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
  }

  &__block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    width: 110px;
    height: 110px;
    z-index: 5;
  }

  &__img {
    width: 53px;
    height: 42px;
    user-select: none;
  }

  &__num {
    font-weight: 700;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #39393f;
    margin-left: 6px;
  }
}
