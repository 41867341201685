.head {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.4;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.1;
    letter-spacing: 0.8px;
  }
}
