.banner {
  margin: 120px 0 60px;

  @media (max-width: $laptop) {
    margin-top: 88px 0 80px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media (max-width: $laptop) {
      flex-direction: column;
      gap: 0;
    }
  }

  &__content {
    width: 450px;
    flex-shrink: 1;

    @media (max-width: $laptop) {
      margin: 0 auto;
      width: 80%;
    }

    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &__title {
    text-transform: uppercase;

    @media (max-width: $laptop) {
      text-align: center;
    }
  }

  &__text {
    margin-top: 40px;
    font-weight: 400;
    font-size: 24px;
    line-height: 108.4%;
    letter-spacing: 0.05em;

    @media (max-width: $laptop) {
      margin-top: 24px;
      font-size: 18px;
      text-align: center;
    }
  }

  &__btn {
    margin-top: 80px;
    max-width: 260px;
    height: 70px;

    @media (max-width: $laptop) {
      margin: 35px auto 0;
      height: 60px;
    }

    @media (max-width: $tablet) {
      max-width: 100%;
    }

    &--desktop {
      @media (max-width: $laptop) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @media (max-width: $laptop) {
        display: flex;
      }
    }
  }

  &__photo {
    max-width: 585px;
    height: 360px;
    flex-shrink: 1;

    @media (max-width: $laptop) {
      margin: 32px auto 0;
    }

    @media (max-width: $tablet) {
      max-width: 100%;
      min-width: 252px;
      height: 172px;
    }

    img,
    source {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
