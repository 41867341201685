.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 102;
  transition: 0.3s;

  @media (max-width: 768px) {
    padding: 15px;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    gap: 20px 0;
    max-width: 550px !important;
    padding: 15px;
    z-index: 10;
  }

  &__content {
    overflow-y: auto;
    max-height: 575px;
    padding: 40px 30px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    border-radius: 10px;

    @media (max-width: 768px) {
      padding: 15px;
    }

    &::-webkit-scrollbar {
      background-color: #b8bfc6;
      height: 100%;
      width: 10px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #46494f;
      border-radius: 5px;
      width: 5px;
    }

    p,
    b {
      font-size: 14px;
      line-height: 130%;
    }

    p {
      text-indent: 20px;
    }

    .link {
      color: #2291e3;
      text-decoration-line: underline;
      text-underline-offset: 2px;
    }

    .title {
      text-align: center;
    }

    &--pb {
      padding-bottom: 120px;

      @media (max-width: 768px) {
        padding-bottom: 200px;
      }
    }
  }

  &-pay {
    align-items: flex-start;

    .title {
      display: flex;
      justify-content: center;
      text-align: center;
      width: 100%;
    }

    .modal-text {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .btn--yellow {
      font-weight: 600;
    }
  }

  &-text {
    font-size: 22px !important;
    font-weight: 600;
    color: #000;

    &--center {
      text-align: center;
    }

    &--thin {
      font-weight: 400;
      font-size: 18px !important;
    }

    &__price {
      color: #cc0000;
    }

    &__bold {
      font-weight: 600;
    }
  }

  &__btns {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    @media (max-width: 768px) {
      flex-wrap: wrap-reverse;
    }

    &--col {
      flex-direction: column;
      width: 100%;
    }
  }

  &__bad {
    color: #cc0000;
  }

  &__success {
    color: #10aa57;
  }

  &-rules {
    &__item {
      font-size: 14px;
      line-height: 120%;
      margin: 6px 0;
      font-weight: 600;
    }
  }

  &__thin {
    font-weight: 400;
    border-radius: 14px;
    letter-spacing: 0.05em;
  }

  &__bold {
    font-weight: 700;
    color: #fff;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 15px;
    background-color: #ffffff;
    padding: 10px 10px 10px 20px;
  }

  &__errors {
    margin-top: 10px;
  }

  .link--inline {
    &::after {
      height: 2px;
    }
  }
}

.modal-hint {
  &__container {
    max-width: 550px !important;
    padding: 15px;
    max-height: 500px;
    height: auto;
    z-index: 5;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    overflow-y: scroll;
  }

  &__content {
    padding: 15px;
  }

  &__btn {
    margin-top: 25px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #39393f;
    white-space: normal;
    text-align: center;
  }

  img {
    width: auto;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
    display: block;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.3s;
}
