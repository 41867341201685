@import "../../../assets/style/main/base/variables.scss";

.progress-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  line-height: 1.4;
  color: $white;
  background-color: $gray-2;
  padding: 16px 32px;
  border-radius: 20px;
  margin-top: auto;
  grid-column: span 2;
  height: fit-content;

  @media (max-width: $laptop) {
    grid-column: span 1;
  }

  @media (max-width: $tablet) {
    padding: 16px;
  }

  &__icon {
    width: 48px;
    height: 48px;
    flex: 0 0 48px;

    @media (max-width: $tablet) {
      width: 36px;
      height: 36px;
      flex: 0 0 36px;
    }

    &--green {
      color: $green;
    }

    &--red {
      color: $red;
    }
  }
}
