@import "../../../assets/style/main/base/variables.scss";

.subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;

  &--green {
    color: $green;
  }

  .price {
    color: $red !important;
    padding-left: 5px;
  }
}
