@import "../../../assets/style/main/base/variables.scss";

.premium {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 65px 0;

  @media (max-width: $laptop) {
    margin: 135px 0 65px;
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 530px;
  }

  &__title {
    text-align: center;
  }

  &__field {
    margin-top: 25px;

    @media (max-width: $tablet) {
      margin-top: 15px;
    }
  }

  &__btns {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }

  &__desc {
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;

    span {
      color: #cc0000;
    }
  }

  &__btn {
    border-radius: 14px;
    font-weight: 500;
  }
}
