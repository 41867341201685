@import '../../../assets/style/main/base/variables.scss';

.alert {
    position: fixed;
    top: 30px;
    right: -200%;
    background-color: $white;
    border: 1px solid $yellow;
    color: $deep-gray;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    padding: 15px 20px;
    z-index: 10;
    border-radius: 20px;
    max-width: 400px;
    transition: .4s;

    @media(max-width: $laptop) {
        top: 60px;
    }

    @media(max-width: $tablet) {
        max-width: 350px;
        padding: 15px;
        font-size: 16px;
    }

    &.show {
        right: 15px;
    }
}