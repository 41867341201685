.tariffs {
    margin-top: 60px;

    &__title {
        @media(max-width: $tablet) {
            text-align: center;
        }
    }

    &__cards {
        margin-top: 70px;
        display: flex;
        justify-content: center;
        gap: 30px;

        @media(max-width: $tablet) {
            flex-direction: column;
            gap: 70px;
            align-items: center;
        }

        @media(max-width: $tablet) {
            margin-top: 65px;
        }
    }
}