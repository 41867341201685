@import "../../../assets/style/main/base/variables.scss";

.error-message {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  line-height: 1.4;
  color: $red;
  text-align: left;
  margin-top: 5px;

  .icon {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
  }

  &--lg {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.8;
  }
}
