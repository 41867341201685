.prise {
    width: 260px;
    height: 315px;

    @media(max-width: $tablet) {
        width: 155px;
        height: 185px;
    }

    &__shine {
        transform: translate(0, 0);
        transition: ease 10s;

        &-bottom {
            transform: translate(0, 0);
        }
    }

    &__star {
        transform-origin: 100px 50px;
        transform: translateX(0) scale(1) rotate(0);
        transition: ease 10s;
    }
}