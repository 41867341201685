* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  scroll-padding-top: 68px;
  min-height: 100%;

  @media (min-width: 992px) {
  }
}

:active,
:focus,
a:active,
a:focus {
  outline: 0;
}

aside,
footer,
header,
nav {
  display: block;
}

body,
html {
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

button,
input,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:hover,
a:visited {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

a {
  color: inherit;
}

body {
  font-family: "Roboto", sans-serif;
  color: #071731;
}

// .container {
//   max-width: 1165px;
//   width: 100%;
//   margin: 0 auto;
//   padding: 0 15px;
// }

.jc-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

// .dropdown {
//   position: relative;

//   &-list {
//     width: 100%;
//     max-height: 110px;
//     background-color: #fff;
//     border: 1px solid #2291e3;
//     ;
//     box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
//     border-radius: 10px;
//     overflow-y: auto;
//     position: absolute;
//     top: 52px;
//     left: 0;
//     z-index: 5;
//     cursor: pointer;
//     transition: .3s;

//     &__item {
//       padding: 10px 35px;
//       font-size: 14px;
//       line-height: 120%;

//       &:hover {
//         background-color: #d4edff;
//       }

//       &--empty {
//         padding: 15px 35px;
//         font-weight: 600;

//         &:hover {
//           background-color: #fff;
//           cursor: default;
//         }
//       }
//     }

//     &::-webkit-scrollbar {
//       display: none;
//     }
//   }

//   &.open {
//     &>.dropdown {
//       &-list {
//         border: 1px solid #2291e3;
//       }
//     }
//   }
// }

.aside {
  width: 320px;
  -webkit-box-flex: 320px;
  -ms-flex: 320px 1;
  flex: 320px 1;
  background: url(../img/bg.png) 50% / cover no-repeat;
  // min-height: 100vh;
  // height: auto;

  &__inner {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    @media (max-width: 992px) {
      min-height: calc(100vh - 60px);
    }
  }

  &__pay {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 30px;
    margin: auto 0 0 0;

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
}

.menu {
  color: #fff;
  width: 100%;

  &__list {
    width: 100%;
  }

  &__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 24px;
    cursor: pointer;

    &:first-child {
      padding-top: 20px;
    }

    &--second {
      padding: 30px 20px;
    }
  }

  &__link {
    position: relative;
    color: #fff;
    text-decoration: none;

    &:after {
      content: "";
      width: 0;
      height: 1px;
      background: #fff;
      display: block;
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      pointer-events: none;
      position: absolute;
      bottom: 0;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    height: 40px;
    width: 211px;
  }

  &__btn {
    max-width: 220px;
    height: fit-content;
  }
}

.content {
  width: calc(100% - 320px);
  -webkit-box-flex: calc(100% - 320px);
  -ms-flex: calc(100% - 320px) 0 1;
  flex: calc(100% - 320px) 0 1;
  max-height: 100vh;
  height: auto;
  overflow: auto;

  @media (max-width: 991px) {
    max-height: 100%;
    min-height: 100%;
    overflow: visible;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 100%;
    background-color: #c4c4c4;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #868282;
  }

  &__inner {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px;
    padding-bottom: 100px;

    @media (max-width: 991px) {
      min-height: 100%;
    }
  }
}

.radiobtns {
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 10px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.register {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  &__title {
    margin-bottom: 6px;
  }

  &__description {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  &__subtext {
    margin-top: 5px;
    padding-left: 30px;
    display: flex;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    text-align: left;
  }

  &__password {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      text-decoration: none;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #000;
        width: 0;
        height: 1px;
        transition: 0.3s;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }

  &__field {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    &--big {
      .radio {
        input[type*="radio"],
        input[type*="checkbox"] {
          & + .radio {
            &__text {
              font-weight: 400;
              font-size: 18px;
              line-height: 100%;
              color: rgba(0, 0, 0, 0.7);
            }
          }
        }
      }
    }

    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;

      &--small {
        font-weight: 400;
        text-align: left;
        padding-left: 35px;

        @media (max-width: 768px) {
          padding-left: 15px;
        }
      }
    }
  }

  // &__select {
  //   margin-top: 10px;
  // }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media (max-width: 768px) {
      gap: 10px;
      flex-direction: column;
    }
  }

  &-desc {
    margin-top: 5px;
    font-weight: 600;
    font-size: 12px;

    &__price {
      color: #cc0000;
    }
  }

  &__condition {
    cursor: pointer;
    font-weight: 600;
    margin: 0 5px;
    text-decoration-line: underline;
    text-underline-offset: 3px;

    &:hover {
      text-decoration: none;
    }

    &--left {
      text-align: left;
    }
  }

  &__payments {
    display: flex;
    flex-direction: column;
  }

  &__link {
    font-weight: 500;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    display: block;
    width: fit-content;
    margin: 20px auto;

    &:hover {
      text-decoration: none;
    }
  }
}

// .select__control {
//   min-height: 45px;
//   width: 100%;
//   border: 1px solid transparent !important;
//   -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//     0 0 0 1px transparent;
//   box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//     0 0 0 1px transparent;
//   border-radius: 10px !important;
//   font-size: 15px;
//   line-height: 18px;
//   -webkit-transition: all 0.2s ease;
//   -o-transition: all 0.2s ease;
//   transition: all 0.2s ease;

//   @media (max-width: 768px) {
//     border: 1px solid rgba(0, 0, 0, 0.1);
//     -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//       0 0 0 1px rgba(0, 0, 0, 0.1);
//     box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//       0 0 0 1px rgba(0, 0, 0, 0.1);
//   }

//   &:hover {
//     -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//       0 0 0 1px #2291e3;
//     box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//       0 0 0 1px #2291e3;
//   }
// }

// .select__value-container {
//   padding-left: 35px !important;

//   @media (max-width: 768px) {
//     padding-left: 15px !important;
//   }
// }

// .error-message {
//   display: flex;
//   text-align: left;
//   color: #cc0000;
//   font-size: 12px;
//   margin-top: 5px;
//   margin-left: 35px;

//   @media (max-width: 768px) {
//     margin-left: 15px;
//   }
// }

.page-block {
  padding: 25px;
  border-radius: 5px;
  border-top: 5px solid #2291e3;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

// .title {
//   font-size: 22px;
//   line-height: 27px;
//   font-weight: 700;
// }

// .input {
//   position: relative;
//   width: 100%;

//   &__placeholder {
//     position: absolute;
//     font-size: 15px;
//     line-height: 18px;
//     top: 12px;
//     left: 35px;
//     background-color: #fff;
//     padding: 3px;
//     cursor: text;
//     -webkit-transition: all 0.2s ease;
//     -o-transition: all 0.2s ease;
//     transition: all 0.2s ease;

//     @media (max-width: 768px) {
//       left: 12px;
//     }
//   }

//   &__input {
//     padding: 15px 35px;
//     height: 50px;
//     width: 100%;
//     border: none;
//     -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//       0 0 0 1px transparent;
//     box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//       0 0 0 1px transparent;
//     border-radius: 10px;
//     font-weight: 700;
//     font-size: 17px;
//     -webkit-transition: all 0.2s ease;
//     -o-transition: all 0.2s ease;
//     transition: all 0.2s ease;
//     line-height: 21px;

//     @media (max-width: 768px) {
//       font-size: 14px;
//       padding: 15px;
//     }

//     &:hover {
//       -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//         0 0 0 1px #2291e3;
//       box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//         0 0 0 1px #2291e3;
//     }

//     &:focus,
//     &:not(:empty),
//     &:not(:placeholder-shown),
//     &:-webkit-autofill {
//       & ~ .field {
//         &__placeholder {
//           -webkit-transform: translateY(-25px);
//           -ms-transform: translateY(-25px);
//           transform: translateY(-25px);
//         }
//       }
//     }

//     &:-webkit-autofill {
//       -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//         0 0 0 1px transparent;
//       box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//         0 0 0 1px transparent;

//       &:focus {
//         -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//           0 0 0 1px #2291e3;
//         box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//           0 0 0 1px #2291e3;
//       }

//       &:hover {
//         -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//           0 0 0 1px #2291e3;
//         box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1), 0 0 0 55px #fff inset,
//           0 0 0 1px #2291e3;
//       }
//     }
//   }
// }

// .btn {
//   display: -webkit-inline-box;
//   display: -ms-inline-flexbox;
//   display: inline-flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   background-color: #2291e3;
//   border-radius: 10px;
//   width: 100%;
//   padding: 15px;
//   height: 44px;
//   font-weight: 700;
//   font-size: 18px;
//   line-height: 22px;
//   color: #fff;
//   -webkit-transition: all 0.4s ease;
//   -o-transition: all 0.4s ease;
//   transition: all 0.4s ease;

//   @media (max-width: 768px) {
//     max-width: 100%;
//   }

//   &:focus,
//   &:hover {
//     background-color: #1875ba;
//   }
// }

.general {
  padding: 0 100px;
  width: 100%;
  margin: auto 0;

  @media (max-width: 1200px) {
    padding: 0 50px;
  }

  &__choose {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    background: #fcfcfc;
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    padding: 15px 10px;

    &-text {
      font-size: 16px;
    }

    &__list {
      margin-top: 10px;
      border-radius: 5px;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      @media (max-width: 1440px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
      }

      @media (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__item {
      margin: 0 !important;
    }
  }

  &__theme {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 15px;
    padding: 10px 50px;

    &__list {
      width: 80%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #1875ba;
      cursor: pointer;

      &:hover {
        background-color: #e6e6e6;
      }
    }

    &__label {
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__rules {
    &__item {
      margin: 15px 0;
      font-weight: 700;
    }
  }

  &__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 30px;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;
    }
  }

  &__exit {
    &__btn {
      display: none !important;
      max-width: 350px;

      @media (max-width: 991px) {
        display: flex !important;
      }

      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
  }

  &__start {
    &__btn {
      max-width: 350px;

      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
}

.header {
  max-width: 900px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  &__name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  &__text {
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__highliht {
    font-weight: 700;
    font-size: 22px;
  }

  &__progress,
  &__timer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  &__progress {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 450px;
    padding: 0 10px;

    &-number {
      font-size: 16px;
      color: #e2e2e2;
      -ms-flex-item-align: end;
      align-self: flex-end;
    }
  }

  &__timer {
    @media (max-width: 991px) {
      display: none !important;
    }
  }

  &__line {
    width: 100%;
    border: 1px solid #2291e3;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    height: 10px;
    margin-bottom: 20px;

    &-inner {
      display: block;
      height: 100%;
      background: #2291e3;
      border-radius: 30px;
    }
  }

  &__number {
    &-current {
      font-weight: 700;
      font-size: 18px;
      color: #071731;
    }
  }
}

.time {
  font-size: 32px;
  font-weight: 700;
}

.time-text {
  font-size: 24px;
  line-height: 120%;
  font-weight: 500;
}

.test {
  max-width: 900px;
  width: 100%;
}

// .test__hint {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;

//   width: auto;
// }

.test__container {
  position: relative;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.test__hint--cursor {
  cursor: pointer;
}

.test__question {
  margin-bottom: 15px;
}

.test__question {
  font-size: 22px;
  line-height: normal;
}

.test__img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40%;
  height: auto;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;

  @media (max-width: 768px) {
    position: static;
    transform: translateY(0);
    width: auto;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media (hover: hover) {
  .test__img:hover {
    width: 70%;

    @media (max-width: 992px) {
      top: 40%;
      width: 55%;
    }

    @media (max-width: 768px) {
      top: 0;
      width: auto;
    }
  }
}

.test__img img {
  width: 100%;
  height: 100%;
}

.test__radio {
  margin: 5px 0;
}

.test__btn {
  margin-top: 20px;
  height: 50px;
}

.test__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.test__item {
  width: 60%;
  margin-left: calc(40% + 20px);

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }

  &.test-full {
    width: 100%;
    margin-left: 0;
  }
}

.hint {
  position: relative;
  width: 35px;
  height: 35px;
  background-color: #146aed;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }

  &-icon {
    width: 14px;
    height: 30px;
  }

  &.disabled {
    cursor: default;
    background-color: #c7c7c7;

    &:hover {
      opacity: 1;
    }

    .hint {
      &-icon {
        &__shine {
          fill: #ebebeb;
        }

        &__gray {
          fill: #a6a6a6;
        }
      }
    }
  }
}

// .hint-show {

// }

.input__radio {
  display: block;
  padding: 15px;
  border: 1px solid #2291e3;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

// .test__input:checked~label {
//   background-color: #2291e3;
//   color: #fff;
// }

.test__input:checked ~ label:not(.footer__btn--true) {
  background-color: #2291e3;
  color: #fff;
}

.input__radio:not(.footer__btn--true):hover {
  background-color: #e6e6e6;
}

@keyframes correct {
  to {
    color: #000;
    background-color: #fff;
  }
}

.table__tests {
  width: 100%;
}

.empty__tests {
  width: 100%;
}

.empty__tests__container {
  width: 100%;
  height: 70vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.empty__tests__container h2 {
  font-size: 27px;
  font-weight: 700;
}

.footer {
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: calc(100vw - 320px);
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.footer__nav {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.footer__btn {
  width: 50px;
  margin: 3px;
  background: #ccc;
  color: #000;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 10px;
  height: 44px;
  min-width: auto;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.footer__btn:focus,
.footer__btn:hover {
  background-color: #1875ba;
}

.footer__btn--true {
  background-color: #28a745;
  color: #fff;
}

.footer__btn--false {
  background: #dc3545 !important;
  color: #fff;
}

.result {
  text-align: center;
  min-width: 320px;

  &__title {
    line-height: 140% !important;
  }

  &__btns {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

    .btn {
      width: calc((100% - 40px) / 3);

      @media (max-width: 1200px) {
        width: calc((100% - 20px) / 2);
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.result__subtitle {
  display: inline-block;
  margin: 0 auto;
  max-width: 550px;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;

  .link--inline {
    color: #2291e3;
  }
}

.result__item {
  margin-top: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  padding: 20px;
  margin-bottom: 15px;
}

.result__item span {
  font-size: 18px;
  font-weight: 700;
}

.result__item--success {
  background: #c9ede2;
  border: 1px solid #c9ede2;
}

.result__item--danger {
  background: #dc3545;
  border: 1px solid #dc3545;
  color: #fff;
}

.result-btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
}

.result__errors {
  margin-top: 30px;
}

.red {
  color: #dc3545;
}

.burger {
  width: 50px;
  height: 40px;
  background: 0 0;
  position: relative;
}

.burger__logo {
  @media (max-width: 991px) {
    flex: 0 0 120px;
  }

  img {
    @media (max-width: 991px) {
      width: 120px;
      height: 30px;
      flex: 0 0 120px;
    }
  }
}

.burger-text {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
}

.burger-text:hover {
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.burger.active span {
  background-color: #fff;
}

.burger.active span:first-child {
  -webkit-transform: translateY(7px) rotate(45deg);
  -ms-transform: translateY(7px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}

.burger.active span:nth-child(2) {
  opacity: 0;
}

.burger.active span:last-child {
  -webkit-transform: translateY(-18px) rotate(-45deg);
  -ms-transform: translateY(-18px) rotate(-45deg);
  transform: translateY(-18px) rotate(-45deg);
}

.burger span {
  width: 100%;
  height: 4px;
  background: #fff;
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.burger span:first-child {
  top: 0;
}

.burger span:nth-child(2) {
  top: calc(50% - 2px);
}

.burger span:last-child {
  bottom: 0;
}

.burger__wrapper {
  position: fixed;
  left: 0;
  top: 0;
  padding: 20px;
  display: none;
}

.swiper-button-next,
.swiper-button-prev {
  top: 0;
  width: 45px !important;
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-container {
  padding: 0 55px !important;
}

.swiper-slide {
  opacity: 1 !important;
  -webkit-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.swiper-slide-visible {
  opacity: 1 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px;
  color: #fff;
}

.d-md-block {
  display: none;
}

@media (max-width: 1500px) {
  .test__inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .number_radio {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 1 !important;
    flex: 0 1 !important;
  }

  .test__radio {
    -webkit-box-flex: calc(100%);
    -ms-flex: calc(100%) 0 1;
    flex: calc(100%) 0 1;
    max-width: calc(100%);
  }

  .page-block {
    padding: 15px;
  }

  .test__btn {
    margin-top: 10px;
  }
}

@media (max-width: 1200px) {
  .header__progress {
    display: none;
  }
}

@media (max-width: 991px) {
  .burger__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #fff;
    font-size: 35px;
    width: 100%;
    background: #2291e3;
    padding: 10px;
    z-index: 101;
  }

  .burger {
    width: 40px;
    height: 30px;
  }

  .aside {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    padding-top: 60px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transform: translate(-100%);
    -ms-transform: translate(-100%);
    transform: translate(-100%);
    background: #2291e3;
  }

  .aside.active {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .menu__logo {
    display: none;
  }

  .content {
    width: 100%;
    -webkit-box-flex: 100%;
    -ms-flex: 100% 0 1;
    flex: 100% 0 1;
    padding-top: 50px;
  }

  .content__inner {
    height: auto;
  }

  .header {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .header__name {
    display: none;
    margin-right: 20px;
  }

  .general {
    padding: 0;
  }

  .general__choose__list {
    border-radius: 5px;
    width: 100%;
    padding: 5px 10px;
    // display: -webkit-box;
    // display: -ms-flexbox;
    // display: flex;
    // -webkit-box-orient: vertical;
    // -webkit-box-direction: normal;
    // -ms-flex-direction: column;
    // flex-direction: column;
    // -webkit-box-pack: center;
    // -ms-flex-pack: center;
    // justify-content: center;
    // -webkit-box-align: center;
    // -ms-flex-align: center;
    // align-items: center;
  }

  .general__choose__item {
    width: 100%;
  }

  .footer {
    position: relative;
    max-width: 100%;
    overflow: hidden;
  }

  .d-md-block {
    display: block;
  }

  .d-md-none {
    display: none;
  }

  .content__inner {
    padding: 20px 16px 20px;
  }
}

@media (max-width: 991px) {
  .number_radio {
    display: inline;
  }

  .footer__nav {
    flex-wrap: wrap;
  }

  .footer {
    padding: 20px 0;
  }
}

@media (max-width: 768px) {
  .test__item {
    display: block;
    margin-bottom: 8px;
  }

  .result-btn-container {
    display: flex !important;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
  }

  .result-btn-container a {
    margin: 0 !important;
  }

  .result-btn-container .btn {
    width: 100%;
  }

  .number_radio {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 1 !important;
    flex: 0 1 !important;
  }

  .test {
    &.page-block {
      margin-top: 0;
      padding: 10px 15px;
    }
  }

  .test__radio {
    -webkit-box-flex: 100%;
    -ms-flex: 100% 0 1;
    flex: 100% 0 1;
    max-width: 100%;
    margin: 5px 0 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  // .test__img {
  //   max-width: 100%;
  //   margin-bottom: 8px;
  // }

  .test__img:hover {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .test__question {
    font-size: 18px;
    line-height: 110%;
    margin-bottom: 8px;
  }

  .test__title {
    font-size: 20px;
    line-height: 110%;
  }

  .input__radio {
    padding: 12px;
  }

  .header {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .header > * {
    width: 100%;
    margin: 10px 0;
  }

  .result {
    min-width: 280px;
    width: 100%;
  }
}

.table__tests {
  overflow: auto;
}

@media (max-width: 400px) {
  .burger__wrapper,
  .burger__wrapper .time {
    font-size: 25px;
  }
}

.test__btn:disabled {
  background: rgba(128, 128, 128, 0.8);
}

.blob {
  // background: black;
  // border-radius: 50%;
  margin: 10px;
  // height: 20px;
  // width: 20px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.correct {
  animation: correct 1.3s 5;
}
