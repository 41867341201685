.textarea {
  height: 96px;

  textarea {
    width: 100%;
    height: 100%;
    resize: none;
    outline: none;
    border: 0;
    margin: 0;
  }
}
