@import "../../../assets/style/main/base/variables.scss";

.auth {
  padding-top: 65px;
  padding-bottom: 65px;

  @media (max-width: $laptop) {
    padding-top: 115px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 530px;
    margin: 0 auto;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__link {
    width: fit-content;
    margin: 12px auto 0;
  }

  &__btns {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 18px;

    &--row {
      flex-direction: row;

      @media (max-width: $laptop) {
        flex-direction: column;
      }
    }
  }

  &__info {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;

    &--bold {
      font-weight: 700;
    }

    span {
      color: $red;
    }
  }

  &__check {
    margin-top: 12px;
  }
}
