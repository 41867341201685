.feedback {
  margin: 60px 0;

  @media (max-width: $tablet) {
    margin: 80px 0;
  }

  &-content {
    max-width: 1170px;
    background: $gray-1;
    border-radius: 20px;
    padding: 44px;
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: $laptop) {
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: $tablet) {
      padding: 38px 38px 32px 38px;
    }

    &__left {
      flex: 0 0 378px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;

      @media (max-width: $tablet) {
        gap: 0px;
      }
    }

    &__form {
      flex: 0 1 528px;
    }
  }

  &__title {
    @media (max-width: $laptop) {
      text-align: center;
    }

    @media (max-width: $tablet) {
      margin: 0 auto;
    }
  }

  &__text {
    margin-top: 24px;
    font-size: 24px;
    line-height: 120%;

    @media (max-width: $laptop) {
      text-align: center;
    }

    @media (max-width: $tablet) {
      margin-top: 24px;
      font-size: 16px;
    }

    &--desktop {
      @media (max-width: $laptop) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @media (max-width: $tablet) {
        display: flex;
      }
    }
  }

  &__img {
    width: 246px;
    height: 260px;
    object-fit: contain;

    @media (max-width: $laptop) {
      margin: 0 auto;
    }

    @media (max-width: $tablet) {
      height: 224px;
      margin-top: 38px;
    }
  }

  &__btn {
    height: 70px;

    @media (max-width: $laptop) {
      height: 60px;
    }
  }
}
