.agreement {
    width: 100%;

    &__title {
        text-align: center;
    }

    &__desc {
        margin-top: 20px;
    }

    &__btn {
        margin-top: 20px;
        text-align: center;
    }

    p {
        text-indent: 20px;
        line-height: 120%;
    }

    h1, h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        text-indent: 20px
    }

    a {
        color: #2291e3;
        text-decoration-line: underline;
        text-underline-offset: 3px;
        cursor: pointer;
    }

    ul {
        padding-left: 30px;
        & > li {
            list-style: disc;
            & > p {
                text-indent: 0;
            }
        }
    }

    &__btn {
        max-width: 180px;
        display: block;
        margin: 20px auto;

        @media(max-width: 768px) {
            max-width: 100%;
        }
    }
}