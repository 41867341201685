@import "../../../assets/style/main/base/variables.scss";

input[type="checkbox"] {
  appearance: none;
  position: absolute;
  height: 1px;
  width: 1px;
  opacity: 0.01;
  transition: all 400ms;
}

.checkbox {
  position: relative;
  text-align: left;

  input[type*="checkbox"] {
    & + .checkbox {
      &__text {
        position: relative;
        padding-left: 32px;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.4;
        color: $deep-gray;
        cursor: pointer;

        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          left: 0;
          border-radius: 5px;
          transition: 0.3s;
        }

        &:before {
          border: 1px solid $deep-gray;
          background-color: $white;
        }

        &:after {
          background-image: url("../../../assets/img/icons/check.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 16px 16px;
          width: 16px;
          height: 16px;
          top: 3px;
          left: 4px;
          opacity: 0;
        }

        a {
          margin-left: 5px;
        }
      }
    }

    &:checked {
      & + .checkbox {
        &__text {
          &:before {
            border: 1px solid $green;
            background-color: $green;
          }

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}
