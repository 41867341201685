.link {
  position: relative;
  transition: 0.4s;

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    right: 0;
    width: 0;
    height: 1px;
    background-color: $deep-gray;
    transition: 0.4s;
  }

  &:hover:not(:disabled) {
    &::after {
      right: initial;
      left: 0;
      width: 100%;
    }
  }

  &:disabled {
    color: $deep-gray;
    opacity: 0.6;
    cursor: not-allowed;
  }

  &--underline {
    &::after {
      width: 100%;
      left: 0;
    }

    &:hover:not(:disabled) {
      &:after {
        right: initial;
        left: 0;
        width: 0;
      }
    }
  }

  &--blue {
    &::after {
      background-color: #2291d5;
    }

    &:hover {
      color: #1d7fbc;

      &::after {
        background-color: #1d7fbc;
      }
    }
  }

  &--sm {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
  }

  &--md {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
  }

  &--lg {
    font-size: 18px;
    line-height: 1.4;
  }

  &--green {
    color: $green;

    &::after {
      background-color: $green;
    }
  }
}
