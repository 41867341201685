.tryit {
  padding: 60px 0 0 0;
  position: relative;
  overflow: hidden;

  @media (max-width: $tablet) {
    padding: 80px 0 0 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../../img/stars-bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1170px 470px;
    width: 1170px;
    height: 470px;
    z-index: 0;

    @media (max-width: $tablet) {
      background-size: contain;
      width: 500px;
      height: 510px;
    }
  }

  &__container {
    max-width: 552px;
    text-align: center;
    position: relative;
    z-index: 1;
  }

  &__desc {
    margin: 24px auto 0;
    width: 270px;

    @media (max-width: $tablet) {
      margin-top: 32px;
      font-size: 16px;
      line-height: 120%;
    }
  }

  &__btn,
  &__img {
    margin: 24px auto 0;

    @media (max-width: $tablet) {
      margin-top: 32px;
    }
  }

  &__btn {
    width: fit-content;
    height: 70px;

    @media (max-width: $laptop) {
      height: 60px;
    }

    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &__img {
    width: 40px;
    height: 68px;
    transform: rotate(360deg);

    &.rotateTime {
      animation: rotateTime 4s linear infinite;
    }
  }

  &__time {
    margin: 24px auto 0;
    width: 270px;
    display: flex;
    flex-direction: column;

    @media (max-width: $tablet) {
      margin-top: 32px;
      font-size: 16px;
      line-height: 120%;
    }

    &-bold {
      font-weight: 600;
    }
  }
}

@keyframes rotateTime {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
