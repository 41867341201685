@import "../../../assets/style/main/base/variables.scss";

.repetition {
  margin-top: 50px;
  margin-bottom: 70px;
  display: grid;
  gap: 55px;

  @media (max-width: $laptop) {
    margin: 130px 0 135px;
  }

  @media (max-width: $tablet) {
    margin: 100px 0 135px;
    gap: 40px;
  }

  &-plug {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 32px;
    opacity: 0.7;

    @media (max-width: $tablet) {
      font-size: 26px;
    }
  }
}
