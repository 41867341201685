.password {
  position: relative;

  &__input {
    padding-right: 55px !important;
    margin-top: 0 !important;
  }

  &__btn {
    position: absolute;
    width: 50px;
    height: 100%;
    top: 0;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }

    &.show {
      opacity: 1;

      .password {
        &__icon {
          &--open {
            opacity: 0;
            pointer-events: none;
          }

          &--close {
            opacity: 1;
            pointer-events: initial;
          }
        }
      }
    }
  }

  &__icon {
    position: absolute;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    transition: 0.3s;

    &--open {
      opacity: 1;
      pointer-events: initial;
    }

    &--close {
      opacity: 0;
      pointer-events: none;
    }
  }
}
