.advantages {
  margin: 60px 0;

  @media (max-width: $tablet) {
    margin: 80px 0 0 0;
  }

  &__title {
    text-align: center;
  }

  &__container {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media (max-width: $tablet) {
      margin-top: 32px;
      flex-direction: column;
      gap: 32px;
    }
  }

  &__photo {
    max-width: 514px;
    height: 335px;

    @media (max-width: $tablet) {
      height: 170px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-content {
    max-width: 488px;
    flex-shrink: 1;
  }

  &-item {
    display: flex;
    gap: 8px;
    font-size: 18px;
    line-height: 180%;
    position: relative;

    @media (max-width: $tablet) {
      margin-top: 5px;
      font-size: 16px;
      line-height: 130%;
    }

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: "";
      background-image: url("../../../img/check.svg");
      background-repeat: no-repeat;
      background-size: 24px 20px;
      width: 24px;
      height: 20px;
      flex: 0 0 24px;
      margin-top: 6px;

      @media (max-width: $tablet) {
        background-size: 22px 18px;
        width: 22px;
        height: 18px;
      }
    }
  }

  &__btn {
    max-width: 270px;
    height: 70px;
    margin: 36px auto 0;

    @media (max-width: $laptop) {
      height: 60px;
    }

    @media (max-width: $tablet) {
      margin-top: 32px;
      max-width: 100%;
    }
  }
}
