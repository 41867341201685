.title {
  font-weight: 400;
  font-size: 56px;
  line-height: 120%;

  @media (max-width: $laptop) {
    font-size: 36px;
  }

  &-md {
    font-weight: 400;
    font-size: 36px;
    letter-spacing: 0;

    @media (max-width: $tablet) {
      line-height: 108.4%;
      letter-spacing: 1.8px;
    }
  }

  &--sm {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
  }
}
