@import "../../../assets/style/main/base/variables.scss";

.progress-general {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 32px;
  background-color: $medium-gray;
  height: 100%;

  @media (max-width: $tablet) {
    padding: 20px;
  }

  &__title {
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 25px;
  }

  .progressbar-car {
    margin: 0 auto 10px;
    width: 155px;
    height: 155px;

    &__img {
      width: 60px;
      height: 48px;
    }

    &__num {
      font-size: 34px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.37;
    max-width: 205px;
    margin: 0 auto;
  }

  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.1;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: $deep-gray;
    background-color: $white;
    padding: 5px 12px;
    border-radius: 14px;
    white-space: nowrap;
    width: fit-content;
    margin: 0 auto 25px;
  }
}
