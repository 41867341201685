@import "../../../assets/style/main/base/variables.scss";

.dropdown {
  position: relative;

  &-list {
    position: absolute;
    top: calc(100% + 40px);
    width: 300px;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 20px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
    z-index: 20;

    @media (max-width: $laptop) {
      right: -50px;
      transform: translateX(0);
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.3;
      color: $deep-gray;
      transition: 0.4s;

      &:hover {
        color: $gray;
      }
    }

    &__icon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $light-gray;
      border-radius: 50%;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  &.open {
    .dropdown {
      &-list {
        top: calc(100% + 28px);
        opacity: 1;
        pointer-events: initial;

        @media (max-width: $laptop) {
          top: calc(100% + 12px);
        }
      }
    }
  }

  &--header {
    .dropdown {
      &-list {
        @media (max-width: $tablet) {
          position: fixed;
          top: 85px;
          left: 16px;
          right: 16px;
          width: calc(100% - 32px);
        }
      }
    }

    &.open {
      .dropdown {
        &-list {
          @media (max-width: $tablet) {
            top: 70px;
          }
        }
      }
    }
  }
}
