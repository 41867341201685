@import "../../../assets/style/main/base/variables.scss";

.input {
  margin-top: 4px;
  border-radius: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  box-shadow: 0 0 0 55px $gray-1 inset, 0 0 0 1px $gray-4;
  border: 0px solid $gray-4;
  color: $deep-gray;
  padding: 5px 10px;
  height: 48px;
  width: 100%;
  transition: 0.3s;

  @media (max-width: $tablet) {
    font-size: 16px;
    margin-top: 4px;
    height: 40px;
  }

  @media (max-width: $tablet) {
    box-shadow: none;
    border: 1px solid $gray-4;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 55px $gray-1 inset, 0 0 0 1px $yellow;
    border: 0px solid $yellow;

    @media (max-width: $tablet) {
      box-shadow: none;
      border: 1px solid $yellow;
    }
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 55px $gray-1 inset, 0 0 0 1px $yellow;

    @media (max-width: 768px) {
      box-shadow: none;
      border: 1px solid $gray-4;
    }

    &:focus {
      box-shadow: 0 0 0 55px $gray-1 inset, 0 0 0 1px $yellow;

      @media (max-width: 768px) {
        box-shadow: none;
        border: 1px solid $yellow;
      }
    }

    &:hover {
      box-shadow: 0 0 0 55px $gray-1 inset, 0 0 0 1px $yellow;

      @media (max-width: 768px) {
        box-shadow: none;
        border: 1px solid $yellow;
      }
    }
  }

  &--sm {
    height: 40px;
    padding-inline: 28px;
    font-size: 16px;
  }

  &--white {
    box-shadow: 0 0 0 1px inset $deep-gray;
    background-color: $white;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      box-shadow: 0 0 0 1px inset $yellow;
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 1px inset $yellow;

      &:focus {
        box-shadow: 0 0 0 1px inset $yellow;
      }

      &:hover {
        box-shadow: 0 0 0 1px inset $yellow;
      }
    }

    &:disabled {
      box-shadow: 0 0 0 1px inset $gray-3;
      color: $gray-3;
      background-color: $gray-1;
      cursor: not-allowed;
    }
  }
}
