@import "../../../../../assets/style/main/base/variables.scss";

.progress-section {
  margin-top: 60px;

  .container {
    display: flex;
    flex-direction: column;
    gap: 36px;
  }

  &__content {
    max-width: 970px;
    width: 100%;
    margin: 0 auto;
  }

  .title {
    @media (max-width: $tablet) {
      text-align: center;
    }
  }
}
