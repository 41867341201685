.about-us {
    padding: 60px 0;
    background-color: $gray-1;

    @media(max-width: $tablet) {
        padding: 40px 0;
    }

    &__title {
        @media(max-width: $tablet) {
            text-align: center;

        }
    }

    &__blocks {
        margin-top: 36px;
        display: flex;
        justify-content: space-between;
        gap: 40px;

        @media(max-width: $laptop) {
            flex-wrap: wrap;
        }

        @media(max-width: $tablet) {
            align-items: center;
            justify-content: center;
        }
    }

    &-block {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        gap: 8px;
        width: 230px;

        @media(max-width: $laptop) {
            width: calc(50% - 20px);
        }

        @media(max-width: $tablet) {
            width: 100%;
            align-items: center;
        }

        &__title {
            line-height: 22px;

            @media(max-width: $tablet) {
                font-size: 16px;
                text-align: center;
            }
        }

        &__text {
            font-weight: 700;
            font-size: 64px;
            line-height: 76px;

            @media(max-width: $tablet) {
                font-weight: 600;
                font-size: 24px;
                line-height: 17px;
                text-align: center;
            }
        }
    }
}