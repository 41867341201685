.plug-page {
  display: flex;
  align-items: center;
  height: calc(100vh - 190px);
  margin: 90px 0;

  @media (max-width: $tablet) {
    margin: 82px 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 40px;

    @media (max-width: $tablet) {
      justify-content: flex-start;
    }
  }

  &__img {
    width: 632px;
    height: 396px;
    object-fit: contain;

    @media (max-width: $tablet) {
      width: 100%;
      height: 226px;
      margin: 0 auto;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 24px;
    line-height: 108.4%;
    text-align: center;
    letter-spacing: 0.05em;

    @media (max-width: $tablet) {
      margin-top: 32px;
      font-size: 18px;
    }
  }

  &__btn {
    @media (max-width: $tablet) {
      margin-top: 80px;
    }
  }
}
