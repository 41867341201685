@import "../../../assets/style/main/base/variables.scss";

.progress-statistics {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  height: 100%;

  &-block {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    padding: 16px 32px;
    background-color: $medium-gray;

    @media (max-width: $tablet) {
      padding: 16px;
    }

    &__icon {
      width: 48px;
      height: 48px;
      flex: 0 0 48px;
      color: $green;

      @media (max-width: $tablet) {
        width: 36px;
        height: 36px;
        flex: 0 0 36px;
      }

      &--red {
        color: $red;
      }
    }

    &__text {
      font-size: 18px;
      line-height: 1.4;
    }

    &__count {
      font-size: 36px;
      line-height: 1.2;
      margin: 0 0 0 auto;

      @media (max-width: $tablet) {
        font-size: 28px;
      }

      &--green {
        color: $green;
      }

      &--red {
        color: $red;
      }
    }
  }
}
