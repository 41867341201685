.form {
    &__field {
        margin-top: 24px;

        @media(max-width: $tablet) {
            margin-top: 12px;
        }
    }

    &__btn {
        margin-top: 24px;
        width: 100%;

        @media(max-width: $tablet) {
            margin-top: 12px;
        }
    }

    &__text {
        margin-top: 24px;
        line-height: 20px;
        color: $gray;

        @media(max-width: $tablet) {
            margin-top: 12px;
            font-size: 13px;
            line-height: 130%;
        } 
    }
}