.finish {
    margin: 60px 0 80px;

    @media(max-width: $tablet) {
        margin: 45px 0;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media(max-width: $tablet) {
            justify-content: flex-start;
        }
    }

    &__circle {
        width: 468px;
        height: 468px;
        display: flex;
        flex: 0 0 468px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        position: relative;

        @media(max-width: $tablet) {
            width: 275px;
            height: 275px;
            flex: 0 0 275px
        }
    }

    &__prise {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);

        @media(max-width: $tablet) {
            top: 57px;
        }
    }

    &__title {
        margin-top: 80px;
        font-weight: 700;
        font-size: 24px;
        line-height: 108.4%;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $deep-gray;

        @media(max-width: $tablet) {
            font-weight: 400;
            font-size: 20px;
            text-transform: capitalize;
            margin-top: 110px;
        }

    }

    &__text {
        font-weight: 400;
        font-size: 20px;
        line-height: 108.4%;
        text-align: center;
        letter-spacing: 0.05em;
        margin-top: 5px;

        @media(max-width: $tablet) {
            font-size: 20px;
        }
    }

    &__title,
    &__text {
        opacity: 0;
        transition: 8s;
    }

    &__btns {
        margin-top: 42px;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid: 20px;

        @media(max-width: $tablet) {
            gap: 30px;
            margin-top: 80px;
        }

        a {
            text-underline-offset: 6px;
        }
    }

    &.animation {
        .prise {
            &__shine {
                transform: translate(15px, -2px);

                &--bottom {
                    transform: translate(15px, 0px);
                }
            }

            &__star {
                transform: translateX(10px) scale(1.3) rotate(360deg);
            }
        }

        .finish {

            &__text,
            &__title {
                opacity: 1;
            }
        }
    }
}