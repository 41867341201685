.qr-code {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    .title {
        font-weight: 500;
        max-width: 600px;
        font-size: 32px;
        text-align: center;
        margin: 0 auto;
        color: #46494F;

        @media(max-width: 768px) {
            font-size: 28px;
        }
    }

    &-content {
        margin-top: 50px;
        display: flex;
        justify-content: center;

        canvas {
            width: 300px !important;
            height: 300px !important;
        }
    }
}