.errors-block {
    display: flex;
    flex-direction: column;

    &-title {
        font-weight: 500;
        font-size: 20px;
        text-align: left;
    }

    ul {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        li {
            font-size: 16px;
            line-height: 120%;
            text-align: left;
            color: $deep-gray;
            
            span {
                text-transform: uppercase;
            }
        }
    }
}