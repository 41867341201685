.select {
  &__control {
    min-height: 40px !important;
    background-color: $white !important;
    border-color: $deep-gray !important;
    border-radius: 20px !important;
    padding-right: 20px;

    &--is-focused {
      box-shadow: none !important;
      border-color: $yellow !important;
    }

    &:hover {
      border-color: $yellow !important;
    }
  }

  &__value-container {
    padding: 0 0 0 28px !important;
  }

  &__multi-value {
    margin: 0 !important;
    background-color: $white !important;
  }

  &__multi-value__label {
    padding: 0 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.2 !important;
  }

  &__multi-value__remove {
    cursor: pointer;

    svg {
      color: $deep-gray !important;
    }

    &:hover {
      background-color: $white !important;

      svg {
        color: $red !important;
      }
    }
  }

  &__input-container {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.4 !important;
    color: $deep-gray !important;

    input {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 1.2 !important;
      color: $deep-gray !important;
    }
  }

  &__placeholder {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 1.2 !important;
    color: $gray !important;
  }

  &__clear-indicator {
    color: $deep-gray !important;
    cursor: pointer;

    &:hover {
      color: $red !important;
    }
  }

  &__indicator-separator {
    margin: 0 !important;
    background-color: $deep-gray !important;
  }

  &__dropdown-indicator {
    background-image: url("../../../img/arrow-down.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {
      opacity: 0;
    }
  }

  &__menu {
    border-radius: 20px !important;
    overflow: hidden;
    box-shadow: 0 0 0 1px $deep-gray, 0 4px 11px hsla(0, 0%, 0%, 0.1) !important;
  }

  &__menu-list {
    padding: 0 !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__option {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 1.2;
    padding: 8px 28px !important;

    &--is-focused {
      background-color: $gray-1 !important;
    }
  }
}
